body {
  letter-spacing: 0.25px;
}
a {
  color: #6750A4;
  cursor: pointer;
}
.App {
  text-align: center;
  background: #F7F7FF;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.app-name {
  font-family: Rockwell;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  letter-spacing: 1px;
  padding: 10px 20px 0px 20px;
  cursor: pointer;
}
.content {
  margin-left: 300px;
  margin-right: 30px;
  margin-top: 50px;
  padding-top: 20px;
  padding-bottom: 30px;
  min-height: calc(100vh - 50px);
}
.log-in {
  background: #E8EAF8;
  max-width: 100%;
  height: 100vh;
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-bar {
  height: 50px;
  align-items: center;
}
.side-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  z-index: 1;
  width: 260px;
  min-height: calc(100vh - 50px);
  left: 0px;
  top: 56px;
  background: #E8EAF8;
  flex-shrink: 0;
}
.nav-menu {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 0.5rem ;
  margin: 0px 10px;
  cursor: pointer;
}
.flex-row {
  display: flex;
  flex-direction: column;
}
.flex-row-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.graph-box {
  height: 450px;
  margin: 15px 0px;
}
.detail-modal {
  width: 800px;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FFFFFF;
  padding: 40px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
}
.setting-modal {
  width: 600px;
  max-height: 75vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FFFFFF;
  padding: 40px;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
}
.title {
  font-size: 1.5rem;
  font-weight: 400;
}
.section-title {
  font-size: 1.25rem;
  font-weight: 400; 
  color: rgba(0, 0, 0, 0.6);
  margin: 10px 0px;
}
.score-board {
  width: 15%;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 1px 2px rgba(151, 131, 131, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin: 20px 0px;
}
.display-table {
  overflow-x: auto;
}
.circle-button {
  border: 1px solid #3F4581 !important;
  box-sizing: border-box !important;
  border-radius: 20px !important;
  padding: 8px !important;
}
.detail-column {
  text-align: left;
  padding-left: 10px;
  line-height: 2.125rem;
}
#left-column {
  width: 250px;
}
.wide-column {
  width: 380px;
}
.full-column {
  width: 100%;
}
.light-gray {
  color: rgba(0, 0, 0, 0.38);
}
.h-34{
  height: 34px;
}